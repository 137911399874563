<div class="gs-connect">
	<div class="gs-connect-box dropdown-menu show" aria-labelledby="gs-connectDropdown" data-bs-popper="none">
		<div class="gs-connect-header">
			<h4>
				Goldstar Connect<span><img (click)="close()" src="../../../../../assets/images/modal-close.png" /></span>
			</h4>
		</div>
		<div class="gs-connect-body">
			<div *ngIf="notificationList.length == 0" class="no-notifications"></div>
			<div *ngFor="let item of notificationList" class="notification-item-fbox" (click)="openGsConnectPage(item)">
				<div class="notification-type-icon" *ngIf="item.notificationType == postNotificationType.REACTION">
					<svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 46.559 46.409">
						<path
							id="reaction-notification"
							d="M23.28,0A23.3,23.3,0,0,0,0,23.28c1.164,30.846,45.4,30.832,46.559,0A23.3,23.3,0,0,0,23.28,0Zm0,42.679a19.421,19.421,0,0,1-19.4-19.4c.97-25.7,37.829-25.693,38.8,0A19.421,19.421,0,0,1,23.28,42.679Zm-9.7-24.25a2.91,2.91,0,1,1,2.91,2.91A2.91,2.91,0,0,1,13.58,18.43Zm13.58,0a2.91,2.91,0,1,1,2.91,2.91A2.91,2.91,0,0,1,27.16,18.43Zm7.453,11.721A13.244,13.244,0,0,1,23.28,36.859a13.262,13.262,0,0,1-11.345-6.708,1.94,1.94,0,0,1,2.033-2.945A29.876,29.876,0,0,0,23.28,29.1a29.875,29.875,0,0,0,9.3-1.893,1.94,1.94,0,0,1,2.037,2.945Z"
							fill="#0097be"
						></path>
					</svg>
				</div>
				<div class="notification-type-icon" *ngIf="item.notificationType == postNotificationType.COMMENT">
					<svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 46.409 46.408">
						<path
							id="comment-notification"
							d="M38.674,0H7.735A7.735,7.735,0,0,0,0,7.735v23.2a7.735,7.735,0,0,0,7.735,7.735h5.608l8.607,7.277a1.934,1.934,0,0,0,2.5,0l8.618-7.277h5.608a7.735,7.735,0,0,0,7.735-7.735V7.735A7.735,7.735,0,0,0,38.674,0ZM13.536,9.668H23.2a1.934,1.934,0,0,1,0,3.867H13.536a1.934,1.934,0,1,1,0-3.867ZM32.873,29.005H13.536a1.934,1.934,0,1,1,0-3.867H32.873a1.934,1.934,0,1,1,0,3.867Zm0-7.735H13.536a1.934,1.934,0,1,1,0-3.867H32.873a1.934,1.934,0,1,1,0,3.867Z"
							fill="#0097be"
						></path>
					</svg>
				</div>
				<div class="notification-type-icon" *ngIf="item.notificationType == postNotificationType.POST && item.gsConnectPostTypeSystemCode == gsConnectPostType.EVENT">
					<svg id="event-notification" xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 47.185 47.935">
						<g id="Group_1127" data-name="Group 1127" transform="translate(0)">
							<path
								id="Path_11316"
								data-name="Path 11316"
								d="M42.242,62.2l-.735,4.368a1.593,1.593,0,0,0,2.309,1.678l3.927-2.05,3.927,2.05a1.593,1.593,0,0,0,2.31-1.678L53.244,62.2l3.162-3.1a1.593,1.593,0,0,0-.882-2.715l-4.382-.65-1.974-3.967a1.594,1.594,0,0,0-2.855,0l-1.972,3.967-4.382.649a1.594,1.594,0,0,0-.882,2.715Z"
								transform="translate(-24.151 -31.343)"
								fill="#0097be"
							></path>
							<path
								id="Path_11317"
								data-name="Path 11317"
								d="M43.4,5.1H38.384V2.313A2.313,2.313,0,0,0,36.071,0H35.7a2.312,2.312,0,0,0-2.312,2.313V5.1h-17.7V2.313A2.313,2.313,0,0,0,13.376,0h-.367A2.312,2.312,0,0,0,10.7,2.313V5.1H5.737a4.765,4.765,0,0,0-4.76,4.76V43.175a4.766,4.766,0,0,0,4.76,4.76H43.4a4.766,4.766,0,0,0,4.76-4.76V9.856A4.766,4.766,0,0,0,43.4,5.1ZM42.78,42.554H6.358V16.617H42.78Z"
								transform="translate(-0.977)"
								fill="#0097be"
							></path>
						</g>
					</svg>
				</div>
				<div class="notification-type-icon" *ngIf="item.notificationType == postNotificationType.POST && gsConnectPostTypeList.includes(item.gsConnectPostTypeSystemCode ?? '')">
					<svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 48.111 40.093">
						<path
							id="post-notification"
							d="M.2,10.019A10.057,10.057,0,0,1,10.023,2H38.088a10.057,10.057,0,0,1,9.823,8.019Zm47.911,4.009V32.069A10.027,10.027,0,0,1,38.088,42.093H10.023A10.027,10.027,0,0,1,0,32.069V14.028ZM20.046,22.046a2.011,2.011,0,0,0-2-2H10.023a2,2,0,0,0,0,4.009h2v8.019a2,2,0,1,0,4.009,0V24.051h2A2.011,2.011,0,0,0,20.046,22.046Zm20.046,8.019a2.011,2.011,0,0,0-2-2H26.06a2,2,0,1,0,0,4.009H38.088A2.011,2.011,0,0,0,40.093,30.065Zm0-8.019a2.011,2.011,0,0,0-2-2H26.06a2,2,0,0,0,0,4.009H38.088A2.011,2.011,0,0,0,40.093,22.046Z"
							transform="translate(0 -2)"
							fill="#0097be"
						></path>
					</svg>
				</div>
				<div class="notification-type-content">
					<p>
						<strong>{{ item.postByUserName }}</strong> {{ item.notificationMessage }}
					</p>
					<span>{{ item.postDateTime ?? '' | postDate }}</span>
				</div>
			</div>
		</div>
		<div class="gs-connect-footer" *ngIf="showOpenGoldstarButton">
			<div class="show-more-btn-sec" (click)="openGsConnectPage()">
				<button type="button" class="show-more-btn">Open Goldstar Connect</button>
			</div>
		</div>
	</div>
</div>
